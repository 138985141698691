<template>
  <v-menu
    v-model="menu"
    ref="menu"
    :close-on-content-click="false"
    :return-value.sync="menu"
    transition="scale-transition"
    offset-y
    bottom
    left
    min-width="auto"
    class="menu-mobile"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        solo
        outlined
        hide-details
        v-bind="attrs"
        v-on="on"
        class="grey-button px-2 w-100"
      >
        <span class="mr-auto">{{ dateRangeComputed }}</span>
        <v-icon size="14px" color="#637381" class="ml-2">unfold_more</v-icon>
      </v-btn>
    </template>
    <div class="d-flex">
      <v-date-picker
        v-model="dateRange"
        :picker-date.sync="pickerDate"
        no-title
        scrollable
        range
        @change="menu = false"
      />
    </div>
  </v-menu>
</template>

<script>
import months from '@/imports/months'
import moment from 'moment'
export default {
  name: 'DatePicker',
  props: {
    dateType: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    pickerDate: null,
    dateRange: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date().setMonth(new Date().getMonth())).format('YYYY-MM-DD')],
    menu: false,
    months
  }),
  computed: {
    dateRangeComputed () {
      if (this.dateType !== 'month') return this.dateRange.toString()

      const [sYear, sMonth, sDay] = this.dateRange[0].split('-')
      const intStartMonth = parseInt(sMonth) - 1

      if (this.dateRange.length < 2) return `${this.months[intStartMonth]} ${sYear}`

      const [eYear, eMonth, eDay] = this.dateRange[1].split('-')
      const intEndMonth = parseInt(eMonth) - 1

      return `${this.months[intStartMonth]} ${sYear} - ${this.months[intEndMonth]} ${eYear}`
    }
  },
  watch: {
    dateRange (val) {
      if (val.length < 2) return
      if (new Date(val[0]) > new Date(val[1])) this.dateRange = [val[1], val[0]]
      if (new Date(this.dateRange[0]) > new Date()) this.dateRange[0] = moment(new Date()).format('YYYY-MM-DD')
      if (new Date(this.dateRange[1]) > new Date()) this.dateRange[1] = moment(new Date()).format('YYYY-MM-DD')
      this.$emit('changeDate', this.dateRange)
    },
    menu (val) {
      const today = new Date().toISOString().split('T')[0]
      this.pickerDate = today
    }
  },
  methods: {
    reset () {
      this.dateRange = [moment(new Date()).format('YYYY-MM-DD'), moment(new Date().setMonth(new Date().getMonth())).format('YYYY-MM-DD')]
    }
  }
}
</script>

<style scoped lang="scss">
.v-menu__content {
  @media (max-width: 959px) {
    right: 0 !important;
    left: 0 !important;
    overflow-x: auto !important;
    max-width: 100% !important;
    margin-top: 12px !important;
  }
}

.v-picker::v-deep .v-date-picker-table {
  table {
    border-collapse: collapse !important;
  }
  td {
    border: 1px solid #e4e4e4 !important;
  }
  tbody td {
    .v-btn {
      border-radius: 0 !important;
      width: 100% !important;
      color: #6770a1 !important;
      &:focus, &:hover {
        border-radius: 0 !important;
      }
    }
    .v-btn--active {
      &.v-date-picker-table__current {
        background-color: #6170C5 !important;
        color: white !important;
      }
      background-color: #6161c547 !important;
      border-radius: 0 !important;
    }
  }
}
</style>
